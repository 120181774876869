/* SipVsLumpSumCalculator.css */
.sip-calculator {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    max-width: 1200px;
    margin: 0 auto;
    padding: 10px;
    color: #2c3e50;
    line-height: 1.6;
  }
  
  .calculator-header {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .calculator-header h1 {
    font-size: 2rem;
    color: #2c3e50;
    margin-bottom: 10px;
  }
  
  .subtitle {
    color: #7f8c8d;
    font-size: 1.1rem;
  }
  
  .calculator-container {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
  }
  
  .input-section {
    flex: 1;
    min-width: 300px;
  }
  
  .results-section {
    flex: 2;
    min-width: 300px;
  }
  
  .input-group {
    background: #f8f9fa;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.05);
  }
  
  .input-group h2 {
    color: #3498db;
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 1.2rem;
  }
  
  .input-row {
    margin-bottom: 15px;
  }
  
  .input-row label {
    display: block;
    margin-bottom: 5px;
    font-weight: 600;
    color: #495057;
  }
  
  .range-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .range-container input[type="range"] {
    flex: 1;
    height: 6px;
    -webkit-appearance: none;
    background: #e9ecef;
    border-radius: 3px;
  }
  
  .range-container input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 16px;
    height: 16px;
    background: #3498db;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .range-container input[type="number"] {
    width: 100px;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .radio-group {
    display: flex;
    gap: 15px;
    margin-top: 5px;
  }
  
  .radio-group label {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
  }
  
  .comparison-summary {
    margin-bottom: 25px;
  }
  
  .summary-cards {
    display: flex;
    gap: 15px;
    margin-bottom: 15px;
  }
  
  .summary-card {
    flex: 1;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.05);
  }
  
  .summary-card.lump-sum {
    border-top: 4px solid #e53935;
  }
  
  .summary-card.sip {
    border-top: 4px solid #388e3c;
  }
  
  .summary-card h3 {
    margin-top: 0;
    color: #2c3e50;
    text-align: center;
  }
  
  .result-row {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    padding-bottom: 5px;
    border-bottom: 1px dashed #eee;
  }
  
  .result-row.total {
    font-weight: 700;
    border-bottom: none;
    margin-top: 15px;
    padding-top: 5px;
    border-top: 1px solid #eee;
  }
  
  .difference-card {
    background: #fff8e1;
    border-radius: 8px;
    padding: 15px;
    text-align: center;
  }
  
  .difference-card h3 {
    margin-top: 0;
    color: #ff8f00;
  }
  
  .difference-amount {
    font-size: 1.2rem;
    font-weight: 700;
    margin: 5px 0 0;
  }
  
  .growth-chart {
    margin-bottom: 25px;
  }
  
  .chart-placeholder {
    background: #f5f5f5;
    height: 300px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #7f8c8d;
  }
  
  .chart-legend {
    display: flex;
    gap: 20px;
    margin-top: 15px;
  }
  
  .legend-item {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .color-box {
    width: 15px;
    height: 15px;
    border-radius: 3px;
  }
  
  .color-box.lump-sum {
    background: #e53935;
  }
  
  .color-box.sip {
    background: #388e3c;
  }
  
  .breakdown-table {
    border: 1px solid #eee;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .table-header, .table-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 12px 15px;
  }
  
  .table-header {
    background: #3498db;
    color: white;
    font-weight: 600;
  }
  
  .table-row:nth-child(even) {
    background-color: #f8f9fa;
  }
  
  @media (max-width: 768px) {
    .summary-cards {
      flex-direction: column;
    }
    
    .range-container {
      flex-direction: column;
      align-items: flex-start;
    }
    
    .range-container input[type="number"] {
      width: 100%;
    }
    
    .table-header, .table-row {
      grid-template-columns: 1fr 1fr;
    }
  }
  
  /* SipVsLumpSumBlog.css */
  .sip-blog {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    padding-top: 50px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    line-height: 1.6;
    color: #333;
  }
  
  .blog-header {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .blog-header h1 {
    font-size: 2rem;
    color: #2c3e50;
    margin-bottom: 10px;
  }
  
  .meta {
    color: #7f8c8d;
    font-size: 0.9rem;
  }
  
  .lead {
    font-size: 1.1rem;
    background: #e3f2fd;
    padding: 20px;
    border-left: 5px solid #1976d2;
    margin: 20px 0;
  }
  
  .calculator-embed {
    margin: 30px 0;
  }
  
  .calculator-placeholder {
    background: #f0f0f0;
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
  }
  
  .content-section h2 {
    color: #2c3e50;
    margin-top: 40px;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
  }
  
  .comparison-table {
    border: 1px solid #eee;
    border-radius: 8px;
    overflow: hidden;
    margin: 20px 0;
  }
  
  .table-row {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    padding: 12px 15px;
  }
  
  .table-row.header {
    background: #3498db;
    color: white;
    font-weight: 600;
  }
  
  .table-row:nth-child(even) {
    background-color: #f8f9fa;
  }
  
  .case-study {
    background: #fff8e1;
    padding: 20px;
    border-radius: 8px;
    margin: 30px 0;
    border-left: 5px solid #ffa000;
  }
  
  .conclusion {
    background: #e8f5e9;
    padding: 20px;
    border-radius: 8px;
    margin-top: 40px;
  }
  
  .tags {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 40px;
  }
  
  .tags span {
    background: #eceff1;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 0.8rem;
    color: #546e7a;
  }
  
  @media (max-width: 768px) {
    .blog-header h1 {
      font-size: 1.6rem;
    }
    
    .lead {
      font-size: 1rem;
      padding: 15px;
    }
    
    .table-row {
      grid-template-columns: 1.5fr 1fr 1fr;
      font-size: 0.9rem;
      padding: 8px 10px;
    }
  }