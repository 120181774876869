/* Base Styles */
.finance-homepage {
    min-height: 100vh;
    background-color: #f8f9fa;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #2c3e50;
    line-height: 1.6;
  }
  
  /* Header Styles */
  .finance-header {
    background: linear-gradient(135deg, #2563eb, #4f46e5);
    color: white;
    padding: 3rem 1rem;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .header-content {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .finance-header h1 {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }
  
  .finance-header p {
    font-size: 1.25rem;
    opacity: 0.9;
  }
  
  /* Main Content Styles */
  .finance-main {
    max-width: 1200px;
    margin: 2rem auto;
    padding: 0 1rem;
  }
  
  .tools-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5rem;
  }
  
  .tool-card {
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    color: white;
    text-decoration: none;
    display: block;
    height: 100%;
  }
  
  .tool-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  }
  
  .card-content {
    padding: 1.5rem;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .tool-icon {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  .tool-card h2 {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 0.75rem;
  }
  
  .tool-card p {
    opacity: 0.9;
    margin-bottom: 1.5rem;
    flex-grow: 1;
  }
  
  .card-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 1rem;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }
  
  .card-footer svg {
    width: 1.25rem;
    height: 1.25rem;
  }
  
  /* Gradient Backgrounds */
  .blue-gradient {
    background: linear-gradient(135deg, #3b82f6, #2563eb);
  }
  .amber-gradient {
    background: linear-gradient(135deg, #f59e0b, #d97706);
  }
  .purple-gradient {
    background: linear-gradient(135deg, #8b5cf6, #7c3aed);
  }
  
  .green-gradient {
    background: linear-gradient(135deg, #10b981, #059669);
  }
  
  .red-gradient {
    background: linear-gradient(135deg, #ef4444, #dc2626);
  }
  
  .yellow-gradient {
    background: linear-gradient(135deg, #f59e0b, #d97706);
  }
  
  .indigo-gradient {
    background: linear-gradient(135deg, #6366f1, #4f46e5);
  }
  
  .orange-gradient {
    background: linear-gradient(135deg, #f97316, #ea580c);
  }
  
  .teal-gradient {
    background: linear-gradient(135deg, #14b8a6, #0d9488);
  }
  
  .pink-gradient {
    background: linear-gradient(135deg, #ec4899, #db2777);
  }
  
  .rose-gradient {
    background: linear-gradient(135deg, #f43f5e, #e11d48);
  }
  
  .cyan-gradient {
    background: linear-gradient(135deg, #06b6d4, #0891b2);
  }
  
  /* Value Props Section */
  .value-props {
    background-color: white;
    padding: 3rem 1rem;
  }
  
  .props-container {
    max-width: 1000px;
    margin: 0 auto;
    text-align: center;
  }
  
  .value-props h2 {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 2rem;
    color: #1e293b;
  }
  
  .props-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
    margin-top: 2rem;
  }
  
  .prop-card {
    background-color: #f8fafc;
    border-radius: 8px;
    padding: 1.5rem;
    transition: all 0.3s ease;
  }
  
  .prop-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  }
  
  .prop-icon {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .prop-card h3 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0.75rem;
    color: #1e293b;
  }
  
  .prop-card p {
    color: #64748b;
  }
  
  /* Footer Styles */
  .finance-footer {
    background-color: #1e293b;
    color: white;
    padding: 2rem 1rem;
  }
  
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .footer-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  
  .footer-brand {
    margin-bottom: 1.5rem;
    text-align: center;
  }
  
  .footer-brand h3 {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }
  
  .footer-brand p {
    color: #94a3b8;
  }
  
  .footer-links {
    display: flex;
    gap: 1.5rem;
  }
  
  .footer-links a {
    color: #e2e8f0;
    text-decoration: none;
    transition: color 0.2s ease;
  }
  
  .footer-links a:hover {
    color: #60a5fa;
  }
  
  .footer-copyright {
    text-align: center;
    color: #94a3b8;
    font-size: 0.875rem;
    padding-top: 1.5rem;
    border-top: 1px solid #334155;
  }
  
  /* Responsive Adjustments */
  @media (min-width: 768px) {
    .finance-header {
      padding: 4rem 1rem;
    }
    
    .finance-header h1 {
      font-size: 3rem;
    }
    
    .finance-header p {
      font-size: 1.5rem;
    }
    
    .footer-main {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    
    .footer-brand {
      margin-bottom: 0;
      text-align: left;
    }
  }
  
  @media (min-width: 1024px) {
    .tools-grid {
      grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    }
  }