  .form-row {
    margin-bottom: 1rem;
    display: flex;
    padding: 10px;
    /* flex-direction: column; */
    align-items: flex-start;
    gap: 0.5rem;
    width: 100%;
  }
  
  .form-label {
    font-weight: 500;
    color: #333;
  }
  
  .form-input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  select.form-input {
    padding: 0.75rem;
    background-color: white;
  }
  
  .options-section .form-label {
    margin-bottom: 1rem;
  }
  
  .option-row {
    display: flex;
    gap: 0.5rem;
    width: 100%;
    margin-bottom: 0.5rem;
  }
  
  .delete-option-btn {
    padding: 0 0.5rem;
    background: #ff4444;
  }
  
  .action-section {
    margin-top: 1.5rem;
  }
  
  .action-buttons {
    display: flex;
    gap: 1rem;
    margin-bottom: 0.5rem;
  }
  
  .btn {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
  }
  
  .status {
    padding: 0.75rem;
    border-radius: 4px;
    font-size: 0.9rem;
  }
  
  .status.success {
    background: #e8f5e9;
    color: #2e7d32;
  }
  
  .status.error {
    background: #ffebee;
    color: #c62828;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .quiz-manage-container {
      padding: 0 0.5rem;
    }
  
    .quiz-card {
      padding: 1rem;
    }
  
    .action-buttons {
      flex-direction: column;
      gap: 0.5rem;
    }
  
    .btn {
      width: 100%;
      text-align: center;
    }
  
    .option-row {
      flex-direction: column;
    }
  
    .delete-option-btn {
      width: fit-content;
    }
  
    .form-input {
      padding: 0.6rem;
    }
  }
  
  @media (max-width: 480px) {
    .options-header {
      flex-direction: column;
      align-items: flex-start;
      gap: 0.5rem;
    }
  
    .add-option-btn {
      width: 100%;
    }
  }

  .quiz-manage-container {
    max-width: 1200px;
    margin: 2rem auto;
    padding: 0 1rem;
  }
  
  .quiz-card {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    padding: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .form-row {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .form-row label {
    min-width: 120px;
    font-weight: 500;
  }
  
  textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    min-height: 80px;
    resize: vertical;
  }
  
  select {
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 200px;
  }
  
  .options-section {
    margin: 1.5rem 0;
    flex-direction: column;
  }
  
  .options-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .option-row {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
  }
  
  .option-row input {
    flex: 1;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .action-buttons {
    display: flex;
    gap: 1rem;
    margin-top: 1.5rem;
  }
  
  button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: opacity 0.2s;
  }
  
  button:hover {
    opacity: 0.9;
  }
  
  .update-btn {
    background: #4CAF50;
    color: white;
  }
  
  .delete-btn {
    background: #f44336;
    color: white;
  }
  
  .add-option-btn {
    background: #2196F3;
    color: white;
  }
  
  .delete-option-btn {
    background: #ff9800;
    color: white;
  }
  
  .loading {
    text-align: center;
    padding: 2rem;
    color: #666;
  }
  
  .error {
    color: #d32f2f;
    padding: 2rem;
    text-align: center;
  }
  
  .message {
    padding: 1rem;
    margin-bottom: 2rem;
    border-radius: 4px;
    text-align: center;
  }
  
  .message.success {
    background: #dff0d8;
    color: #3c763d;
  }
  
  .message.error {
    background: #f2dede;
    color: #a94442;
  }


  .quiz-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 5rem 2rem;
    max-width: 800px;
    margin: 0 auto;
  }

  .card {
    background: white;
    border: 1px solid var(--border-color);
    border-radius: 12px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    min-height: 280px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .card-body {
    padding: 1.5rem;
    flex: 1;
  }
  
  .question-text {
    font-size: 1.125rem;
    line-height: 1.5;
    margin-bottom: 1.25rem;
    color: #1f2937;
    min-height: 4em;
  }
  
  .options-container {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
  
  .option-item {
    margin: auto 10px 10px 10px;
    display: flex;
    align-items: flex-start;
    text-align: left;
    padding: 1rem;
    border: 2px solid var(--border-color);
    border-radius: 8px;
    transition: all 0.2s ease;
    cursor: not-allowed;
    background: white;
  }
  
  .option-item.revealed {
    border-color: var(--success-color);
    background-color: #f0fdf4;
    box-shadow: 0 0 0 3px rgba(22, 163, 74, 0.2);
  }
  
  .option-text {
    margin-left: 0.75rem;
    line-height: 1.4;
    color: #374151;
    white-space: pre-wrap;
    word-break: break-word;
  }
  