.rent-vs-buy-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    padding-top: 50px;
    font-family: Arial, sans-serif;
    color: #333;
  }
  
  h2 {
    text-align: center;
    color: #2c3e50;
    margin-bottom: 30px;
  }
  
  .input-section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    margin-bottom: 30px;
  }
  
  .input-group {
    background: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  }
  
  .input-group h3 {
    margin-top: 0;
    color: #3498db;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
  }
  
  .input-row {
    margin-bottom: 15px;
  }
  
  .input-row label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .input-row input[type="number"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .input-row input[type="range"] {
    width: 100%;
    margin: 10px 0;
  }
  
  .slider-input {
    width: 60px !important;
    margin-left: 10px;
  }
  
  .results-section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    margin-bottom: 30px;
  }
  
  .result-card {
    background: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  }
  
  .result-card h3 {
    margin-top: 0;
    color: #2ecc71;
  }
  
  .result-card p {
    margin: 10px 0;
  }
  
  .summary {
    background: #e8f4fc;
    padding: 20px;
    border-radius: 8px;
    border-left: 5px solid #3498db;
  }
  
  .summary h3 {
    margin-top: 0;
    color: #3498db;
  }
  
  strong {
    color: #2c3e50;
  }
  
  @media (max-width: 768px) {
    .input-section, .results-section {
      grid-template-columns: 1fr;
    }
  }

  .blog-post {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    padding-top: 50px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    line-height: 1.6;
    color: #333;
  }
  
  .blog-header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .blog-header h1 {
    color: #2c3e50;
    font-size: 2.2rem;
    margin-bottom: 10px;
  }
  
  .meta {
    color: #7f8c8d;
    font-size: 0.9rem;
  }
  
  .lead {
    font-size: 1.2rem;
    background: #e8f4fc;
    padding: 20px;
    border-left: 5px solid #3498db;
    margin: 30px 0;
  }
  
  .calculator-cta {
    background: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    margin: 30px 0;
    text-align: center;
  }
  
  .calculator-cta h2 {
    color: #2ecc71;
  }
  
  .calculator-placeholder {
    background: #e0e0e0;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    border-radius: 5px;
  }
  
  .comparison-section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
    margin: 40px 0;
  }
  
  .pros-cons {
    background: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
  }
  
  .pros-cons h3 {
    color: #3498db;
    margin-top: 0;
  }
  
  .pros {
    color: #27ae60;
    list-style-type: none;
    padding-left: 0;
  }
  
  .cons {
    color: #e74c3c;
    list-style-type: none;
    padding-left: 0;
  }
  
  .formula {
    background: #f0f0f0;
    padding: 15px;
    border-radius: 5px;
    font-family: monospace;
    margin: 15px 0;
    overflow-x: auto;
  }
  
  .case-study {
    background: #fff8e1;
    padding: 20px;
    border-left: 5px solid #f39c12;
    margin: 30px 0;
  }
  
  .conclusion {
    background: #e8f5e9;
    padding: 25px;
    border-radius: 8px;
    margin-top: 40px;
  }
  
  .blog-footer {
    margin-top: 50px;
  }
  
  .cta-box {
    background: #3498db;
    color: white;
    padding: 25px;
    border-radius: 8px;
    text-align: center;
  }
  
  .cta-box h3 {
    margin-top: 0;
  }
  
  .tags {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
  }
  
  .tags span {
    background: #ecf0f1;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 0.8rem;
  }
  
  @media (max-width: 768px) {
    .blog-header h1 {
      font-size: 1.8rem;
    }
    
    .comparison-section {
      grid-template-columns: 1fr;
    }
  }

  /* Update these styles in your BlogPost.css */

/* For regular lists in content */
.blog-content ul {
    padding-left: 20px;
    margin: 15px 0;
  }
  
  .blog-content li {
    margin-bottom: 8px;
    position: relative;
    padding-left: 25px;
    text-align: left;
  }
  
  /* For pros/cons lists specifically */
  .pros-cons ul {
    padding-left: 0;
  }
  
  .pros-cons li {
    padding-left: 30px;
    position: relative;
    margin-bottom: 10px;
    text-align: left;
    line-height: 1.5;
  }
  
  .pros li:before {
    content: "✓";
    position: absolute;
    left: 0;
    color: #27ae60;
    font-weight: bold;
  }
  
  .cons li:before {
    content: "✗";
    position: absolute;
    left: 0;
    color: #e74c3c;
    font-weight: bold;
  }
  
  /* Remove any conflicting text-align properties */
  .blog-content {
    text-align: left;
  }