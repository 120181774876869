/* ProductListPage.css */
.top {
  padding-top: 50px;
}
.product-list-page {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
}

.page-title {
  text-align: center;
  margin-bottom: 20px;
}

.category-columns {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.category-column {
  flex: 1;
  max-width: 50%; /* Ensure each column does not exceed 50% of screen width */
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 15px;
  background-color: #f5f5f5;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.category-title {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #333;
}

.product-list {
  list-style: none;
  padding: 0;
}

.product-item {
  background-color: #f9f9f9;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  /* Ensure text wraps within the container */
  white-space: nowrap;
  overflow: hidden;
  /* Display ellipsis (...) for overflowing text */
  text-overflow: ellipsis;
  text-align: left;
}

.product-item a {
  /* Ensure text wraps within the container */
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Limit to 3 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: left;;
}

@media (max-width: 768px) {
  .category-column {
    max-width: 100%; /* Change to full width on smaller screens */
  }
}
