:root {
    --primary-color: #2563eb;
    --success-color: #16a34a;
    --border-color: #e5e7eb;
  }
  
  .quiz-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 5rem 2rem;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .card {
    background: white;
    border: 1px solid var(--border-color);
    border-radius: 12px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    min-height: 280px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .card-body {
    padding: 1.5rem;
    flex: 1;
  }
  
  .question-text {
    font-size: 1.125rem;
    line-height: 1.5;
    margin-bottom: 1.25rem;
    color: #1f2937;
    min-height: 4em;
  }
  
  .options-container {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
  
  .option-item {
    margin: auto 10px 10px 10px;
    display: flex;
    align-items: flex-start;
    text-align: left;
    padding: 1rem;
    border: 2px solid var(--border-color);
    border-radius: 8px;
    transition: all 0.2s ease;
    cursor: not-allowed;
    background: white;
  }
  
  .option-item.revealed {
    border-color: var(--success-color);
    background-color: #f0fdf4;
    box-shadow: 0 0 0 3px rgba(22, 163, 74, 0.2);
  }
  
  .option-text {
    margin-left: 0.75rem;
    line-height: 1.4;
    color: #374151;
    white-space: pre-wrap;
    word-break: break-word;
  }
  
  .reveal-button {
    margin: auto 10px 10px 10px;
    background: var(--primary-color);
    color: white;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 12px 12px 12px 12px;
    font-weight: 500;
    cursor: pointer;
    transition: opacity 0.2s ease;
  }
  
  .reveal-button:disabled {
    background: #94a3b8;
    cursor: not-allowed;
  }
  
  @media (max-width: 640px) {
    .quiz-container {
      padding: 4rem 1rem;
    }
    
    .card {
      min-height: 300px;
    }
    
    .card-body {
      padding: 1rem;
    }
    
    .question-text {
      min-height: 3em;
    }
  }