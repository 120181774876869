.admin-panel {
  max-width: 800px;
  margin: 2rem auto;
  padding: 5rem;
  background: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
}

.admin-panel.validation-error {
  max-width: 95vw;
  width: 100%;
  margin: 1rem auto;
  padding: 5rem;
}
.status-message.success {
  background: #c6f6d5;
  color: #22543d;
}

.status-message.error {
  background: #fed7d7;
  color: #822727;
}

.status-message.validation-failed {
  background: #feebc8;
  color: #7b341e;
}

.answer-row {
  align-items: center;
}

.answer-input {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.answer-input .error-text {
  white-space: nowrap;
  margin: 0;
}

@media (max-width: 640px) {
  .admin-panel.validation-error {
    margin: 0;
    border-radius: 0;
    min-height: 100vh;
  }
  
  .answer-input {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }
  
  .answer-input input {
    width: 100%;
  }
}

.form-group {
  margin-bottom: 1rem;
}
.row {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
}

.col-label {
  flex: 0 0 140px;
  text-align: left;
}

.col-input {
  flex: 1;
}

textarea, input[type="text"], input[type="number"] {
  width: 100%;
  transition: all 0.3s ease;
}

.admin-panel.validation-error textarea,
.admin-panel.validation-error input[type="text"] {
  padding: 1rem;
  font-size: 16px;
  min-height: 120px;
}

.textarea {
  min-height: 80px;
  resize: vertical;
}

.textarea.error, input.error {
  border: 1px solid red;
}
.options-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.add-option {
  background: #e2e8f0;
  color: #1a365d;
  border: none;
  padding: 0.4rem 0.8rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 13px;
}

.submit-btn {
  display: block;
  width: 100%;
  background: #4299e1;
  color: white;
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  margin-top: 1.5rem;
}

.admin-panel .error-text {
  /* Your original error text styles */
  color: #e53e3e;
  font-size: 0.875rem;
  margin-top: 0.25rem;
  display: block;
}

.admin-panel .error {
  /* Your admin panel error state styles */
  border-color: #e53e3e !important;
}

@media (max-width: 640px) {
  .admin-panel {
    padding: 1rem;
    margin: 1rem;
  }
  
  .row {
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .col-label {
    flex: none;
    padding-top: 0;
  }
  
  .options-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }
}

.vendor-select {
  width: 100%;
  padding: 0.5rem;
  border: 2px solid #cbd5e0;
  border-radius: 4px;
  font-size: 14px;
  background: white;
  cursor: pointer;
}

.vendor-select:focus {
  outline: none;
  border-color: #2563eb;
}

.submission-message {
  margin-top: 1rem;
  padding: 0.75rem;
  border-radius: 4px;
  text-align: center;
}

.success-msg {
  background-color: #c6f6d5;
  color: #22543d;
}

.error-msg {
  background-color: #fed7d7;
  color: #822727;
}