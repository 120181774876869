*{
    margin: 0;
    padding: 0;
}

.trending-grid-container{
  display: flex;
  overflow-y: scroll;
  justify-content: flex-start;
  margin-top: 10px;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.grid-container::-webkit-scrollbar {
    display: none;
}

.card-outer{
  margin: 10px;
  height: 250px;
  width: 580px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 15px;
  /* box-shadow: 0 0 15px rgb(0 0 128 / 14%); */
  box-shadow: 0 0 15px #6c757d;
}

.card-text-area{
  /* background-color: #289D86; */
  height: inherit;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

/* .trending-grid-container div:nth-child(6n+1) .card-outer .card-text-area   { background-color: #289D86; } */
.trending-grid-container div:nth-child(6n+1) .card-outer .card-text-area   { background-image: linear-gradient(to right, #289D86, #fff); }
.trending-grid-container div:nth-child(6n+2) .card-outer .card-text-area   { background-image: linear-gradient(to right,#9D9928, #fff); }
.trending-grid-container div:nth-child(6n+3) .card-outer .card-text-area   { background-image: linear-gradient(to right,#9D283A, #fff); }
.trending-grid-container div:nth-child(6n+4) .card-outer .card-text-area   { background-image: linear-gradient(to right,#6B289D, #fff); }
.trending-grid-container div:nth-child(6n+5) .card-outer .card-text-area   { background-image: linear-gradient(to right,#9D2875, #fff); }
.trending-grid-container div:nth-child(6n+6) .card-outer .card-text-area   { background-image: linear-gradient(to right,#282D9D, #fff); }

.card-mrp{
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-mrp-value{
  font-size:x-large;
  color: #372b2b;
  font-style: oblique;
}

.card-price-text{
  font-size:x-large;
  color: #fff;
}

.card-price-value{
  font-size:x-large;
  color: #6c2626;
}

.card-title{
  /* font-size: larger; */
  color: rgb(0, 0, 19);
  max-width: 99%;
  margin: 6%;
  margin-bottom: 10%;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  font-size: 120%;
  font-family: Roboto, Arial, sans-serif;
  line-height: 120%; /* fallback */
  max-height: 360%; /* fallback */
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.card-button{
  height: 14%;
  aspect-ratio: 5/1;
  border-radius: 25px;
  background-color: #fff;
  /* margin-top: 6%; */
  /* margin-bottom: 1%; */
  position: absolute;
  bottom: 3%;
}

.card-button-text{
  font-size: x-large;
  font-weight: bold;
  color: #289D86;
}

.card-image-area{
  height: inherit;
  width: 45%;
}

.card-image{
  height: 70%;
  aspect-ratio: 1 / 1;
  margin-top: 12%;
}

.card-vendor-logo{
  margin-top: 10%;
  height: 8%;
  aspect-ratio: 3/1;

}

@media only screen and (max-width: 600px) {
  .card-outer{
    width: 450px;
    height: 200px;
  }
  .card-button{
    height: 12%;
    bottom: 2%;
  }
  .card-button-text{
    font-size:large;
    font-weight: bold;
    color: #289D86;
  }
  .card-title{
    font-size: 100%;
    margin-bottom: 2%;
    line-height: 120%; /* fallback */
    max-height: 360%; /* fallback */
  }
  .card-mrp-value{
    font-size:medium;
  }
  .card-price-text{
    font-size:medium;
  }
}
@media only screen and (max-width: 500px) {
  .card-outer{
    width: 350px;
    height: 180px;
  }
  .card-button{
    height: 12%;
    bottom: 2%;
  }
  .card-button-text{
    font-size:medium;
    font-weight: bold;
    color: #289D86;
  }
  .card-title{
    font-size: 90%;
    margin-bottom: 2%;
    line-height: 120%; /* fallback */
    max-height: 360%; /* fallback */
  }
  .card-mrp-value{
    font-size:medium;
  }
  .card-price-text{
    font-size:medium;
  }
}
@media only screen and (max-width: 390px) {
  .card-outer{
    width: 360px;
    height: 180px;
  }
  .card-title{
    font-size: 100%;
    margin-bottom: 2%;
    line-height: 120%; /* fallback */
    max-height: 360%; /* fallback */
  }
  .card-button{
    height: 11%;
    bottom: 2%;
  }
  .card-button-text{
    font-size: small;
    font-weight: bold;
    color: #289D86;
  }
  .card-mrp-value{
    font-size:large;
  }
  
  .card-price-text{
    font-size:large;
  }
  
  .card-price-value{
    font-size:large;
  }
}
@media only screen and (max-width: 390px) {
  .card-outer{
    width: 300px;
  }
}
.card-text-area-skeleton{
  background-color:  #f0f0f0;;
  height: inherit;
  width: 580px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}
.card-text-area-skeleton::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.5), transparent);
  animation: loading 1.5s infinite linear;
}

@keyframes loading {
  100% {
    left: 100%;
  }
}
