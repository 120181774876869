/* RetirementPlanner.css */
.retirement-planner {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    color: #2c3e50;
    line-height: 1.6;
  }
  
  .planner-header {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .planner-header h1 {
    font-size: 2rem;
    color: #2c3e50;
    margin-bottom: 10px;
  }
  
  .subtitle {
    color: #7f8c8d;
    font-size: 1.1rem;
  }
  
  .planner-container {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
  }
  
  .input-section {
    flex: 1;
    min-width: 300px;
  }
  
  .results-section {
    flex: 2;
    min-width: 300px;
  }
  
  .input-group {
    background: #f8f9fa;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.05);
  }
  
  .input-group h2 {
    color: #3498db;
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 1.2rem;
  }
  
  .input-row {
    margin-bottom: 15px;
  }
  
  .input-row label {
    display: block;
    margin-bottom: 5px;
    font-weight: 600;
    color: #495057;
  }
  
  .range-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .range-container input[type="range"] {
    flex: 1;
    height: 6px;
    -webkit-appearance: none;
    background: #e9ecef;
    border-radius: 3px;
  }
  
  .range-container input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 16px;
    height: 16px;
    background: #3498db;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .range-container input[type="number"] {
    width: 100px;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .radio-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 5px;
  }
  
  .radio-group label {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }
  
  .reality-check {
    margin-bottom: 25px;
  }
  
  .reality-card {
    background: #e3f2fd;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    border-left: 5px solid #1976d2;
  }
  
  .reality-card p {
    margin: 5px 0;
  }
  
  .corpus-amount {
    font-size: 1.8rem;
    font-weight: 700;
    color: #1976d2;
    margin: 10px 0;
  }
  
  .action-required {
    margin-bottom: 25px;
  }
  
  .action-card {
    background: #e8f5e9;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    border-left: 5px solid #388e3c;
  }
  
  .investment-amount {
    font-size: 1.8rem;
    font-weight: 700;
    color: #388e3c;
    margin: 10px 0;
  }
  
  .withdrawal-projections {
    margin-bottom: 25px;
  }
  
  .projection-table {
    border: 1px solid #eee;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .table-header, .table-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 12px 15px;
  }
  
  .table-header {
    background: #3498db;
    color: white;
    font-weight: 600;
  }
  
  .table-row:nth-child(even) {
    background-color: #f8f9fa;
  }
  
  .table-footer {
    padding: 10px 15px;
    text-align: center;
    color: #7f8c8d;
    font-size: 0.9rem;
    border-top: 1px solid #eee;
  }
  
  .chart-placeholder {
    /* margin-bottom: 20px; */
    /* display: none; */
  }
  
  .chart-container {
    width: 100%;
    height: 400px;
    margin-top: 20px;
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.05);
  }
  .chart-legend {
    display: flex;
    gap: 20px;
    margin-top: 15px;
  }
  
  .legend-item {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .color-box {
    width: 15px;
    height: 15px;
    border-radius: 3px;
  }
  
  .color-box.corpus {
    background: #1976d2;
  }
  
  .color-box.withdrawal {
    background: #388e3c;
  }
  
  @media (max-width: 768px) {
    .range-container {
      flex-direction: column;
      align-items: flex-start;
    }
    
    .range-container input[type="number"] {
      width: 100%;
    }
    
    .table-header, .table-row {
      grid-template-columns: 1fr 1fr;
    }
  }
  
  /* RetirementPlannerBlog.css */
  .retirement-blog {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    padding-top: 50px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    line-height: 1.6;
    color: #333;
  }
  
  .blog-header {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .blog-header h1 {
    font-size: 2rem;
    color: #2c3e50;
    margin-bottom: 10px;
  }
  
  .meta {
    color: #7f8c8d;
    font-size: 0.9rem;
  }
  
  .lead {
    font-size: 1.1rem;
    background: #fff8e1;
    padding: 20px;
    border-left: 5px solid #ffa000;
    margin: 20px 0;
  }
  
  .calculator-embed {
    margin: 30px 0;
  }
  
  .calculator-placeholder {
    background: #f0f0f0;
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
  }
  
  .content-section h2 {
    color: #2c3e50;
    margin-top: 40px;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
  }
  
  .mistakes-list {
    margin: 25px 0;
  }
  
  .mistake-item {
    background: #f8f9fa;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 15px;
  }
  
  .mistake-item h3 {
    color: #d32f2f;
    margin-top: 0;
  }
  
  .calculation-steps {
    background: #e8f5e9;
    padding: 20px;
    border-radius: 8px;
    margin: 25px 0;
  }
  
  .calculation-steps h3 {
    color: #2e7d32;
  }
  
  .case-study {
    background: #e3f2fd;
    padding: 20px;
    border-radius: 8px;
    margin: 30px 0;
  }
  
  .strategy-comparison {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    margin: 25px 0;
  }
  
  .strategy {
    background: #f8f9fa;
    border-radius: 8px;
    padding: 15px;
  }
  
  .strategy h3 {
    color: #1976d2;
    margin-top: 0;
  }
  
  .action-items {
    background: #fff8e1;
    padding: 20px;
    border-radius: 8px;
    margin: 30px 0;
  }
  
  .action-items ol {
    padding-left: 20px;
  }
  
  .action-items li {
    margin-bottom: 10px;
  }
  
  .tags {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 40px;
  }
  
  .tags span {
    background: #eceff1;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 0.8rem;
    color: #546e7a;
  }
  
  @media (max-width: 768px) {
    .blog-header h1 {
      font-size: 1.6rem;
    }
    
    .lead {
      font-size: 1rem;
      padding: 15px;
    }
  }