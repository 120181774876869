.about-us-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .about-us-content {
    /* margin-top: 80vh; */
    max-width: 900px;
    padding: 30px;
    background-color: #f2f2f2;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  h1 {
    font-size: 28px;
    margin-bottom: 10px;
  }
  
  p {
    margin-bottom: 15px;
    line-height: 1.5;
  }

  u {
    color: blue;  
  }
.disclaimer {
    color: red;
    text-align: left;
  }

.left-align {
    text-align: left;
}