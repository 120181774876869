/* Base Styles */
.foreclosure-calculator {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  padding-top: 50px;
  color: #2c3e50;
  line-height: 1.6;
}

.foreclosure-calculator h1 {
  color: #2c3e50;
  text-align: center;
  margin-bottom: 10px;
}

.subtitle {
  text-align: center;
  color: #7f8c8d;
  margin-bottom: 30px;
  font-size: 1.1rem;
}

/* Calculator Layout */
.calculator-container {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin-bottom: 40px;
}

.input-section {
  flex: 1;
  min-width: 300px;
}

.results-section {
  flex: 2;
  min-width: 300px;
}

/* Input Groups */
.input-group {
  background: #f8f9fa;
  border-radius: 10px;
  padding: 25px;
  margin-bottom: 25px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.05);
}

.input-group h2 {
  color: #3498db;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 1.3rem;
}

/* Input Rows */
.input-row {
  margin-bottom: 20px;
}

.input-row label {
  display: block;
  margin-bottom: 8px;
  font-weight: 600;
  color: #495057;
}

.range-container {
  display: flex;
  align-items: center;
  gap: 15px;
}

.range-container input[type="range"] {
  flex: 1;
  height: 8px;
  -webkit-appearance: none;
  background: #e9ecef;
  border-radius: 5px;
}

.range-container input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  background: #3498db;
  border-radius: 50%;
  cursor: pointer;
}

.range-container input[type="number"] {
  width: 120px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

/* Radio Buttons */
.radio-group {
  display: flex;
  gap: 20px;
  margin-top: 10px;
}

.radio-group label {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.radio-group input[type="radio"] {
  width: 18px;
  height: 18px;
}

/* Results Section */
.emi-comparison, .foreclosure-savings, .payment-schedule {
  background: white;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.05);
}

.emi-comparison h2, .foreclosure-savings h2, .payment-schedule h2 {
  margin-top: 0;
  color: #3498db;
  font-size: 1.3rem;
}

.emi-cards {
  display: flex;
  gap: 20px;
  margin-top: 15px;
}

.emi-card {
  flex: 1;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid #eee;
  text-align: center;
}

.emi-card.active {
  border-color: #3498db;
  background-color: #e3f2fd;
}

.emi-card h3 {
  margin-top: 0;
  color: #2c3e50;
}

.emi-card .amount {
  font-size: 1.5rem;
  font-weight: 700;
  color: #27ae60;
  margin: 10px 0;
}

.emi-card .note {
  color: #7f8c8d;
  font-size: 0.9rem;
  margin: 5px 0 0;
}

.savings-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
  margin-top: 15px;
}

.savings-card {
  background: #e8f5e9;
  border-radius: 8px;
  padding: 15px;
  text-align: center;
}

.savings-card h3 {
  margin-top: 0;
  color: #2e7d32;
  font-size: 1rem;
}

.savings-card .amount {
  font-size: 1.3rem;
  font-weight: 700;
  color: #2e7d32;
  margin: 5px 0 0;
}

/* Payment Schedule */
.schedule-table {
  margin-top: 15px;
  border: 1px solid #eee;
  border-radius: 8px;
  overflow: hidden;
}

.table-header, .table-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 12px 15px;
}

.table-header {
  background: #3498db;
  color: white;
  font-weight: 600;
}

.table-row:nth-child(even) {
  background-color: #f8f9fa;
}

.table-footer {
  padding: 10px 15px;
  text-align: center;
  color: #7f8c8d;
  font-size: 0.9rem;
  border-top: 1px solid #eee;
}

/* SEO Content */
.seo-content {
  background: #f8f9fa;
  border-radius: 10px;
  padding: 25px;
  margin-top: 30px;
}

.seo-content h2 {
  color: #2c3e50;
  margin-top: 0;
}

.seo-content h3 {
  color: #2c3e50;
}

.seo-content ul {
  padding-left: 20px;
}

.seo-content li {
  margin-bottom: 8px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .emi-cards {
    flex-direction: column;
  }
  
  .range-container {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  
  .range-container input[type="number"] {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .table-header, .table-row {
    grid-template-columns: 1fr 1fr;
  }
  
  .table-header div:nth-child(3),
  .table-row div:nth-child(3),
  .table-header div:nth-child(4),
  .table-row div:nth-child(4) {
    display: none;
  }
  
  .input-group {
    padding: 15px;
  }
}