/* NRI Investment Comparison Tool CSS */
.nri-investment-tool, .nri-investment-blog {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    padding-top: 50px;
    color: #2c3e50;
    line-height: 1.6;
  }
  
  .tool-header, .blog-header {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .tool-header h1, .blog-header h1 {
    font-size: 2rem;
    color: #2c3e50;
    margin-bottom: 10px;
  }
  
  .subtitle, .meta {
    color: #7f8c8d;
    font-size: 1.1rem;
  }
  
  .lead {
    font-size: 1.1rem;
    background: #e3f2fd;
    padding: 20px;
    border-left: 5px solid #1976d2;
    margin: 20px 0;
  }
  
  .tool-container {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
  }
  
  .input-section {
    flex: 1;
    min-width: 300px;
  }
  
  .results-section {
    flex: 2;
    min-width: 300px;
  }
  
  .input-group {
    background: #f8f9fa;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.05);
  }
  
  .input-group h2 {
    color: #3498db;
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 1.2rem;
  }
  
  .input-row {
    margin-bottom: 15px;
  }
  
  .input-row label {
    display: block;
    margin-bottom: 5px;
    font-weight: 600;
    color: #495057;
  }
  
  .range-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .range-container input[type="range"] {
    flex: 1;
    height: 6px;
    -webkit-appearance: none;
    background: #e9ecef;
    border-radius: 3px;
  }
  
  .range-container input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 16px;
    height: 16px;
    background: #3498db;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .range-container input[type="number"] {
    width: 100px;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    background: white;
  }
  
  .summary-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 15px;
    margin-bottom: 25px;
  }
  
  .summary-card {
    background: white;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.05);
    text-align: center;
  }
  
  .summary-card h3 {
    margin-top: 0;
    color: #2c3e50;
    font-size: 1rem;
  }
  
  .summary-card .amount {
    font-size: 1.3rem;
    font-weight: 700;
    margin: 5px 0;
  }
  
  .summary-card .subtext {
    color: #7f8c8d;
    font-size: 0.9rem;
    margin: 0;
  }
  
  .tax-info {
    margin-top: 10px;
    font-size: 0.8rem;
    color: #6c757d;
    display: flex;
    justify-content: space-between;
  }
  
  .projection-chart,
  .tax-comparison {
    margin-bottom: 25px;
  }
  
  .chart-container {
    width: 100%;
    height: 400px;
    margin-top: 20px;
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.05);
  }
  
  .educational-content {
    margin-top: 40px;
    background: #f8f9fa;
    padding: 30px;
    border-radius: 10px;
  }
  
  .strategy-explanations {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    margin: 25px 0;
  }
  
  .strategy {
    background: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.05);
  }
  
  .strategy h3 {
    color: #1976d2;
    margin-top: 0;
  }
  
  .case-study {
    background: #fff8e1;
    padding: 20px;
    border-radius: 8px;
    margin: 20px 0;
    border-left: 5px solid #ffa000;
  }
  
  .country-comparison {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    margin: 25px 0;
  }
  
  .country {
    background: white;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  }
  
  .tips-section {
    background: #e8f5e9;
    padding: 20px;
    border-radius: 8px;
    margin: 25px 0;
  }
  
  .conclusion {
    background: #f5f5f5;
    padding: 20px;
    border-radius: 8px;
    margin-top: 40px;
  }
  
  .tags {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 40px;
  }
  
  .tags span {
    background: #eceff1;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 0.8rem;
    color: #546e7a;
  }
  
  /* Responsive adjustments */
  @media (max-width: 992px) {
    .tool-container {
      flex-direction: column;
    }
    
    .input-section, .results-section {
      flex: none;
      width: 100%;
    }
    
    .summary-cards {
      grid-template-columns: 1fr 1fr;
    }
  }
  
  @media (max-width: 768px) {
    .tool-header h1, .blog-header h1 {
      font-size: 1.6rem;
    }
    
    .lead {
      font-size: 1rem;
      padding: 15px;
    }
    
    .range-container {
      flex-direction: column;
      align-items: flex-start;
    }
    
    .range-container input[type="number"] {
      width: 100%;
    }
    
    .summary-cards {
      grid-template-columns: 1fr;
    }
    
    .chart-container {
      height: 300px;
      padding: 10px;
    }
    
    .strategy-explanations, .country-comparison {
      grid-template-columns: 1fr;
    }
  }
  
  @media (max-width: 480px) {
    .nri-investment-tool, .nri-investment-blog {
      padding: 15px;
    }
    
    .input-group {
      padding: 15px;
    }
    
    .chart-container {
      height: 250px;
    }
    
    .educational-content {
      padding: 20px;
    }
  }
  
  /* Animation for better interactivity */
  .summary-card, .strategy, .country {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .summary-card:hover, .strategy:hover, .country:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
  }
  
  /* Custom scrollbar for better UX */
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #3498db;
    border-radius: 4px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #2980b9;
  }