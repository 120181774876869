*{
  margin: 0;
  padding: 0;
  cursor: default;
  font-family: Roboto,Arial,sans-serif;
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu4mxK.woff2) format('woff2');
  font-display: swap; /* Change to 'optional' if not critical */
  ascent-override: 95%; /* Prevent layout shift */
  descent-override: 25%;
}

body {
  font-family: 'Roboto', sans-serif;
}
.home-page{
  overflow-x: hidden;
}
.App {
  text-align: center;
  margin: 0;
  padding: 0;
  overflow-x:hidden;
}
.main-content {
  flex: 1;
  margin-top: 0px;
  padding: 20px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafc;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.bold-text{
  font-weight: bold;
  font-size: x-large;
  color: rgba(19, 30, 180, 0.883);
}

.vspace{
  height: 200rem;
}

.infinite-scroll-component::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}


