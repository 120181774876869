.title{
    color: #98e164;
    font-size: 30px;
    padding-left: 10px;
    font-family: Roboto;
    display: flex;
}
.title-text{
    color: #98e164;
    font-size: 30px;
    padding-left: 10px;
    font-family: Roboto;
    display: flex;
}
@media screen and (max-width: 520px) {
    .title {
        display:none !important;
    }
}

.search-form {
    display: flex;
    flex-direction: row;
    position: fixed;
    top: 5px;
    right: 10px;
    margin-left: 45px;
    width: auto;
}