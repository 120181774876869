/* CreditCardDebtCalculator.css */
.debt-calculator {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    padding-top: 50px;
    color: #2c3e50;
    line-height: 1.6;
  }
  
  .calculator-header {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .calculator-header h1 {
    font-size: 2rem;
    color: #2c3e50;
    margin-bottom: 10px;
  }
  
  .subtitle {
    color: #7f8c8d;
    font-size: 1.1rem;
  }
  
  .calculator-container {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
  }
  
  .input-section {
    flex: 1;
    min-width: 300px;
  }
  
  .results-section {
    flex: 2;
    min-width: 300px;
  }
  
  .input-group {
    background: #f8f9fa;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.05);
  }
  
  .input-group h2 {
    color: #3498db;
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 1.2rem;
  }
  
  .debt-card {
    background: white;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 15px;
    position: relative;
    box-shadow: 0 1px 5px rgba(0,0,0,0.05);
  }
  
  .remove-debt {
    position: absolute;
    top: 5px;
    right: 5px;
    background: #e53935;
    color: white;
    border: none;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 16px;
    line-height: 1;
  }
  
  .input-row {
    margin-bottom: 15px;
  }
  
  .input-row label {
    display: block;
    margin-bottom: 5px;
    font-weight: 600;
    color: #495057;
  }
  
  .input-row input[type="text"],
  .input-row input[type="number"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .range-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .range-container input[type="range"] {
    flex: 1;
    height: 6px;
    -webkit-appearance: none;
    background: #e9ecef;
    border-radius: 3px;
  }
  
  .range-container input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 16px;
    height: 16px;
    background: #3498db;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .range-container input[type="number"] {
    width: 100px;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .radio-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 5px;
  }
  
  .radio-group label {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }
  
  .add-debt {
    width: 100%;
    padding: 10px;
    background: #e3f2fd;
    border: 1px dashed #3498db;
    border-radius: 4px;
    color: #3498db;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .add-debt:hover {
    background: #bbdefb;
  }
  
  .summary-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 15px;
    margin-bottom: 25px;
  }
  
  .summary-card {
    background: white;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.05);
    text-align: center;
  }
  
  .summary-card h3 {
    margin-top: 0;
    color: #2c3e50;
    font-size: 1rem;
  }
  
  .summary-card .amount {
    font-size: 1.3rem;
    font-weight: 700;
    margin: 5px 0;
  }
  
  .summary-card .subtext {
    color: #7f8c8d;
    font-size: 0.9rem;
    margin: 0;
  }
  
  .strategy-comparison,
  .payment-plan,
  .detailed-plan {
    margin-bottom: 25px;
  }
  
  .chart-container {
    width: 100%;
    height: 400px;
    margin-top: 20px;
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.05);
  }
  
  .table-container {
    overflow-x: auto;
    margin-top: 15px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #eee;
  }
  
  th {
    background-color: #3498db;
    color: white;
  }
  
  tr:nth-child(even) {
    background-color: #f8f9fa;
  }
  
  .educational-content {
    margin-top: 40px;
    background: #f8f9fa;
    padding: 30px;
    border-radius: 10px;
  }
  
  .strategy-explanations {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    margin: 25px 0;
  }
  
  .strategy {
    background: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.05);
  }
  
  .strategy h3 {
    color: #1976d2;
    margin-top: 0;
  }
  
  .case-study {
    background: #fff8e1;
    padding: 20px;
    border-radius: 8px;
    margin: 20px 0;
    border-left: 5px solid #ffa000;
  }
  
  .tips-section {
    background: #e8f5e9;
    padding: 20px;
    border-radius: 8px;
    margin: 25px 0;
  }
  
  @media (max-width: 768px) {
    .calculator-container {
      flex-direction: column;
    }
    
    .range-container {
      flex-direction: column;
      align-items: flex-start;
    }
    
    .range-container input[type="number"] {
      width: 100%;
    }
  }
  
  /* Blog specific styles */
  .debt-blog {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    padding-top: 50px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    line-height: 1.6;
    color: #333;
  }
  
  .blog-header {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .blog-header h1 {
    font-size: 2rem;
    color: #2c3e50;
    margin-bottom: 10px;
  }
  
  .meta {
    color: #7f8c8d;
    font-size: 0.9rem;
  }
  
  .lead {
    font-size: 1.1rem;
    background: #e3f2fd;
    padding: 20px;
    border-left: 5px solid #1976d2;
    margin: 20px 0;
  }
  
  .content-section h2 {
    color: #2c3e50;
    margin-top: 40px;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
  }
  
  .comparison {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    margin: 25px 0;
  }
  
  .pros-cons {
    background: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.05);
  }
  
  .pros-cons h3 {
    color: #1976d2;
    margin-top: 0;
  }
  
  .pros h4, .cons h4 {
    margin-bottom: 10px;
  }
  
  .pros {
    margin-bottom: 20px;
  }
  
  .pros ul {
    background: #e8f5e9;
    padding: 15px;
    border-radius: 5px;
    list-style-position: inside;
  }
  
  .cons ul {
    background: #ffebee;
    padding: 15px;
    border-radius: 5px;
    list-style-position: inside;
  }
  
  .strategies {
    background: #e3f2fd;
    padding: 20px;
    border-radius: 8px;
    margin: 25px 0;
  }
  
  .conclusion {
    background: #f5f5f5;
    padding: 20px;
    border-radius: 8px;
    margin-top: 40px;
  }
  
  .tags {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 40px;
  }
  
  .tags span {
    background: #eceff1;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 0.8rem;
    color: #546e7a;
  }
  
  @media (max-width: 768px) {
    .blog-header h1 {
      font-size: 1.6rem;
    }
    
    .lead {
      font-size: 1rem;
      padding: 15px;
    }
  }