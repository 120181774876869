.add_item_form {
    padding-top: 1px;
  }
  .add_item_form_title {
    font-size: 36px;
    font-weight: 500;
    padding: 7px;
    color:darkblue;
  }
  .add_item_form_input {
    margin-bottom: 30px;
    width: 100%;
  }
  .input_field {
    width: calc(33.33333% - 20px);
    height: 50px;
    padding-left: 25px;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    outline: none;
    transition: all 0.2s ease;
  }
  .input_field:focus,
  .input_field:hover,
  .text_field:focus,
  .text_field:hover {
    border-color: #b2b2b2;
  }
  .input_field::-webkit-input-placeholder,
  .text_field::-webkit-input-placeholder {
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.3);
  }
  .input_field:-moz-placeholder,
  .input_field::-moz-placeholder,
  .text_field:-moz-placeholder,
  .text_field::-moz-placeholder {
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.3);
  }
  .input_field:-ms-input-placeholder,
  .text_field:-ms-input-placeholder {
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.3);
  }
  .input_field::input-placeholder,
  .text_field::input-placeholder {
    font-size: 16px;
    font-weight: 400;
    color: rgba(233, 44, 44, 0.3);
  }

  .add_item_submit_button {
    padding-left: 35px;
    padding-right: 35px;
    color: rgba(16, 69, 241, 0.911);
    font-size: 18px;
    border: none;
    outline: none;
    cursor: pointer;
    margin-top: 24px;
  }

  .add_item_fluid{ /*root_buttom form*/
    border: #f5118e;
  }
  
  .add_item_info_item{
    display: flex;
    background-color: #fff;
    border-radius: 15;
  }
  
  .add_item_form{ /*root_top form*/
    display: flex;
    justify-content: space-around;
  }
  
  .add_item_form_name{
    border: #055;
    box-shadow: #f5118e;
  }
  

  .add_item_form_input_small{
    width: 10rem;
    margin: 10px;
  }

  .small_inputs{
      display: flex;
      justify-content: space-around;
      /* width: 66.5rem; */
  }

  .preview{
    position: fixed;
    top: 0;
    width:100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .preview-inner{
    position: relative;
    padding: 32px;
    margin-top: 4%;
    height: 37rem;
    width: 100rem;
    max-width: 700px;
    background-color: #efe;

  }
   
  .preview-inner .save-btn{
    display: flex;
    justify-content: space-around;
    bottom: 16px;
    margin-top: 10px;
  }

  .preview-card{
    margin-top: 5%;
    margin-bottom: 10%;
  }
  .card-inner{
    max-width: 18rem;
  }

  .table-container {
    height: 85vh;
    padding-left: 4%;
    padding-top: 4%;
    padding-right: 8%;
    overflow: auto;
  }

  .label * {
    vertical-align: middle;
  }

  .card-img-top-sm {
    object-fit: cover;
    max-height: 6rem;
  }

  .imgOriginalDivSm {
    height: 6rem;
    z-index: 1;
    object-fit: contain;
  }

  .admin-card-item {
    padding-top: 10px;
    height: 27rem;
    background-color: #fff;
    border-radius: 15px;
    /* box-shadow: 0 0 15px rgb(0 0 128 / 14%); */
    box-shadow: 0 0 15px #6c757d;
  }

.admin-grid-item {
  padding: 5px;
  width: 17.5rem;
  padding-bottom: 20px;
  text-align: center;
}