/* RealEstateROICalculator.css */
.roi-calculator {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    color: #2c3e50;
    line-height: 1.6;
  }
  
  .calculator-header {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .calculator-header h1 {
    font-size: 2rem;
    color: #2c3e50;
    margin-bottom: 10px;
  }
  
  .subtitle {
    color: #7f8c8d;
    font-size: 1.1rem;
  }
  
  .calculator-container {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
  }
  
  .input-section {
    flex: 1;
    min-width: 300px;
  }
  
  .results-section {
    flex: 2;
    min-width: 300px;
  }
  
  .input-group {
    background: #f8f9fa;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.05);
  }
  
  .input-group h2 {
    color: #3498db;
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 1.2rem;
  }
  
  .input-row {
    margin-bottom: 15px;
  }
  
  .input-row label {
    display: block;
    margin-bottom: 5px;
    font-weight: 600;
    color: #495057;
  }
  
  .range-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .range-container input[type="range"] {
    flex: 1;
    height: 6px;
    -webkit-appearance: none;
    background: #e9ecef;
    border-radius: 3px;
  }
  
  .range-container input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 16px;
    height: 16px;
    background: #3498db;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .range-container input[type="number"] {
    width: 100px;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .reality-check {
    margin-bottom: 25px;
  }
  
  .reality-card {
    background: #fff8e1;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    border-left: 5px solid #ffc107;
  }
  
  .reality-card p {
    margin: 5px 0;
  }
  
  .actual-yield {
    font-size: 1.5rem;
    font-weight: 700;
    color: #e53935;
  }
  
  .summary-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 15px;
    margin-bottom: 25px;
  }
  
  .summary-card {
    background: white;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.05);
    text-align: center;
  }
  
  .summary-card h3 {
    margin-top: 0;
    color: #6c757d;
    font-size: 1rem;
  }
  
  .summary-card .amount {
    font-size: 1.3rem;
    font-weight: 700;
    margin: 5px 0;
    color: #2c3e50;
  }
  
  .summary-card .subtext {
    color: #7f8c8d;
    font-size: 0.8rem;
    margin: 0;
  }
  
  .comparison-section {
    margin-bottom: 25px;
  }
  
  .comparison-cards {
    display: flex;
    gap: 15px;
  }
  
  .comparison-card {
    flex: 1;
    padding: 15px;
    border-radius: 8px;
    text-align: center;
  }
  
  .comparison-card.property {
    background: #e3f2fd;
    border: 1px solid #bbdefb;
  }
  
  .comparison-card.reit {
    background: #e8f5e9;
    border: 1px solid #c8e6c9;
  }
  
  .comparison-card h3 {
    margin-top: 0;
    color: #2c3e50;
  }
  
  .comparison-card .value {
    font-size: 1.3rem;
    font-weight: 700;
    margin: 10px 0;
  }
  
  .comparison-card.property .value {
    color: #1976d2;
  }
  
  .comparison-card.reit .value {
    color: #2e7d32;
  }
  
  .comparison-card .return {
    color: #7f8c8d;
    margin: 0;
    font-size: 0.9rem;
  }
  
  .breakdown-table {
    border: 1px solid #eee;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .table-header, .table-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 12px 15px;
  }
  
  .table-header {
    background: #3498db;
    color: white;
    font-weight: 600;
  }
  
  .table-row:nth-child(even) {
    background-color: #f8f9fa;
  }
  
  @media (max-width: 768px) {
    .comparison-cards {
      flex-direction: column;
    }
    
    .range-container {
      flex-direction: column;
      align-items: flex-start;
    }
    
    .range-container input[type="number"] {
      width: 100%;
    }
    
    .table-header, .table-row {
      grid-template-columns: 1fr 1fr;
    }
  }
  
  /* RealEstateROIBlog.css */
  .roi-blog {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    padding-top: 50px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    line-height: 1.6;
    color: #333;
  }
  
  .blog-header {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .blog-header h1 {
    font-size: 2rem;
    color: #2c3e50;
    margin-bottom: 10px;
  }
  
  .meta {
    color: #7f8c8d;
    font-size: 0.9rem;
  }
  
  .lead {
    font-size: 1.1rem;
    background: #e3f2fd;
    padding: 20px;
    border-left: 5px solid #1976d2;
    margin: 20px 0;
  }
  
  .calculator-embed {
    margin: 30px 0;
  }
  
  .calculator-placeholder {
    background: #f0f0f0;
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
  }
  
  .content-section h2 {
    color: #2c3e50;
    margin-top: 40px;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
  }
  
  .cost-breakdown h3 {
    color: #3498db;
    margin-top: 25px;
  }
  
  .case-study {
    background: #fff8e1;
    padding: 20px;
    border-radius: 8px;
    margin: 30px 0;
    border-left: 5px solid #ffa000;
  }
  
  .conclusion {
    background: #e8f5e9;
    padding: 20px;
    border-radius: 8px;
    margin-top: 40px;
  }
  
  .tags {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 40px;
  }
  
  .tags span {
    background: #eceff1;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 0.8rem;
    color: #546e7a;
  }
  
  @media (max-width: 768px) {
    .blog-header h1 {
      font-size: 1.6rem;
    }
    
    .lead {
      font-size: 1rem;
      padding: 15px;
    }
  }