/* EMI Calculator Container */
.emi-calculator {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    color: #333;
    box-sizing: border-box;
  }
  
  .emi-calculator h2 {
    color: #2c3e50;
    text-align: center;
    margin-bottom: 30px;
    font-size: 28px;
  }
  
  /* Calculator Layout */
  .calculator-container {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
  }
  
  .input-section {
    flex: 1;
    min-width: 300px;
  }
  
  .results-section {
    flex: 1;
    min-width: 300px;
  }
  
  /* Input Groups */
  .input-group {
    background: #f8f9fa;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.05);
  }
  
  .input-group h3 {
    color: #3498db;
    margin-top: 0;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
    font-size: 20px;
  }
  
  /* Input Rows */
  .input-row {
    margin-bottom: 20px;
  }
  
  .input-row label {
    display: block;
    margin-bottom: 8px;
    font-weight: 600;
    color: #495057;
  }
  
  .input-row input[type="range"] {
    width: 100%;
    height: 8px;
    -webkit-appearance: none;
    background: #e9ecef;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .input-row input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    background: #3498db;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .input-with-unit {
    display: flex;
    align-items: center;
  }
  
  .input-with-unit input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .input-with-unit span {
    margin-left: 10px;
    color: #6c757d;
  }
  
  select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
    background: white;
  }
  
  /* Results Section */
  .summary-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 15px;
    margin-bottom: 30px;
  }
  
  .summary-card {
    background: white;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.05);
    text-align: center;
  }
  
  .summary-card h4 {
    margin-top: 0;
    color: #6c757d;
    font-size: 16px;
  }
  
  .summary-card .amount {
    font-size: 20px;
    font-weight: 700;
    margin: 10px 0 0;
    color: #2c3e50;
  }
  
  .summary-card.highlight {
    background: #e3f2fd;
    border: 1px solid #bbdefb;
  }
  
  .summary-card.highlight .amount {
    color: #1976d2;
  }
  
  /* Chart Container */
  .chart-container {
    background: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.05);
  }
  
  .chart-container h3 {
    margin-top: 0;
    color: #495057;
    font-size: 18px;
  }
  
  .chart-wrapper {
    position: relative;
    height: 300px;
    width: 100%;
  }
  
  /* Tips Section */
  .prepayment-tips {
    background: #e8f5e9;
    border-radius: 10px;
    padding: 20px;
    margin-top: 30px;
  }
  
  .prepayment-tips h3 {
    color: #2e7d32;
    margin-top: 0;
  }
  
  .prepayment-tips ul {
    padding-left: 20px;
  }
  
  .prepayment-tips li {
    margin-bottom: 10px;
    line-height: 1.5;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .calculator-container {
      flex-direction: column;
    }
    
    .summary-cards {
      grid-template-columns: 1fr 1fr;
    }
    
    .chart-wrapper {
      height: 250px;
    }
  }
  
  @media (max-width: 480px) {
    .emi-calculator {
      padding: 15px;
    }
    
    .summary-cards {
      grid-template-columns: 1fr;
    }
    
    .input-group, .chart-container, .prepayment-tips {
      padding: 15px;
    }
  }
  
  /* Chart.js Overrides */
  canvas {
    max-width: 100% !important;
    height: auto !important;
  }
  /* EMI Calculator Container */
.emi-calculator {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  padding-top: 50px;
  color: #333;
  box-sizing: border-box;
  line-height: 1.5;
}

/* Input Rows - Fixed Spacing */
.input-row {
  margin-bottom: 25px; /* Increased spacing */
  display: flex;
  flex-direction: column;
  gap: 8px; /* Space between label and inputs */
}

.input-row label {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
  color: #495057;
  width: 100%;
}

/* Input Groups - Better Organization */
.input-group {
  background: #f8f9fa;
  border-radius: 10px;
  padding: 25px; /* More padding */
  margin-bottom: 25px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.05);
  display: flex;
  flex-direction: column;
  gap: 15px; /* Space between input rows */
}

/* Range Input Styling */
.input-row input[type="range"] {
  width: 100%;
  margin: 10px 0 15px 0; /* More vertical spacing */
}

/* Number Input Styling */
.input-with-unit {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between input and unit */
  width: 100%;
}

.input-with-unit input[type="number"] {
  flex: 1;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  min-width: 0; /* Prevent overflow */
}

/* Select Dropdown Styling */
.input-row select {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  background: white;
  margin-top: 5px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .input-group {
    padding: 20px;
  }
  
  .input-row {
    margin-bottom: 20px;
  }
}

@media (max-width: 480px) {
  .input-group {
    padding: 15px;
  }
  
  .input-with-unit {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .input-with-unit span {
    margin-left: 0;
    margin-top: 5px;
  }
}