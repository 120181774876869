* {
  padding: 0;
  margin: 0;
  justify-content: center;
  text-align: center;
}

body {
  background: radial-gradient(#e5e5e5, #ffff, #e5e5e5);
}

/* Card container (outer wrapper) */
.card-container {
  width: 100%;
  max-width: 1240px; /* Optional: set max width for larger screens */
  margin: 0 auto;
  padding: 35px 10px;
  background-color: #e5e5e5;
}

/* Grid container */
.grid-container {
  display: grid;
  gap: 16px;
  grid-auto-rows: 362px; /* Fixed row height */
  grid-template-columns: repeat(2, 1fr); /* Default mobile: 2 columns */
}

/* Grid items */
.grid-item {
  min-width: 0; /* Prevent overflow */
  height: 100%; /* Take full height of grid row */
}

/* Card item (content inside grid item) */
.card-item {
  /* height: 362px; Fixed height */
  width: 100%;
  background: #fff;
  border-radius: 8px;
  overflow: hidden; /* Prevent content overflow */
  box-shadow: 0 0 15px #6c757d;
}

/* Responsive breakpoints */
@media (min-width: 768px) { /* Medium screens */
  .grid-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1200px) { /* Large screens */
  .grid-container {
    grid-template-columns: repeat(4, 1fr);
  }
}

.card {
  padding-left: 4px;
}

.card:hover {
  box-shadow: 5px 10px 20px 1px rgba(0, 0, 0, 253) !important;
}

.card-body {
  padding: 1rem 0 !important;
}

.card-text {
  font-size: 0.9rem;
  padding: 0.4rem 1.9rem;
}

.text-description {
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 16px; /* fallback */
  max-height: 32px; /* fallback */
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  color: black;
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto, Arial, sans-serif;
  letter-spacing: 0;
  min-height: 32px; 
}

.container-fluid {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.overflow {
  overflow: hidden;
}

.btn-get-deal {
  background-color: #25bb90;
  padding: 4px;
  border: #e5e5e5 1px solid;
  margin-left: 10px;
  margin-right: 10px;
  font-weight: bold;
  border-radius: 10px;
  height: 34px;
  color: rgb(255, 255, 255);
  cursor: pointer;
}


@media only screen and (max-width: 710px) {
  
  .card {
    width: 11rem;
    padding-left: 4px;
  }
}

 
.grid-container-update {
  display: grid;
  justify-content: space-evenly;
  grid-template-columns: auto auto auto auto;
  background-color: #fff;
  padding-top: 10px;
}

@media only screen and (max-width: 1235px) {
  .grid-container-update {
    display: grid;
    grid-template-columns: auto auto auto;
    background-color: #fff;
    padding: 10px;
  }
}

@media only screen and (max-width: 853px) {
  .grid-container-update {
    display: grid;
    grid-template-columns: auto auto;
    background-color: #fff;
    padding: 5px;
  }
}

@media only screen and (max-width: 562px) {
  .grid-container-update {
    display: grid;
    grid-template-columns: auto;
    background-color: #fff;
  }

}


.grid-item-display {
  padding: 5px;
  text-align: center;
  display: block;
}


.preview {
  z-index: 1;
}

.card-inner {
  width: 30%;
}

.comment-preview {
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  text-align: center;
  position: fixed;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.71);
}
.comment-preview-inner {
  margin-top: 60px;
  margin-bottom: 1%;
  height: 95%-70px;
  width: 90%;
  background-color: #ebeff5;
  border-radius: 25px;
  display: list-item;
}

.comment-form-content {
  position: absolute;
  bottom: 5vh;
  left: 7%;
  width: 85%;
}

.add_comment_input {
  width: 100%;
  border-radius: 5px;
  text-align: justify;
  box-shadow: 0 0 15px rgb(0 0 128 / 14%);
  font-family: "Open Sans", sans-serif;
  background-color: rgba(255, 255, 255);
}

.save-btn {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.profile-img {
  object-fit: contain;
  height: 3rem;
  width: 3rem;
  margin: 4px;
}

.text-left {
  text-align: justify;
  padding-left: 10px;
  padding-right: 10px;
}

.comment-card {
  display: block;
  border-radius: 5px;
  margin-bottom: 9px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 0 5px rgb(0 0 128 / 14%);
}
.custom-font-weight-light {
  font-weight: 100;
  font-size: smaller;
  font-style: italic;
}

.comment-box {
  position: fixed;
  margin-left: 2%;
  width: 85%;
  height: 60vh;
  overflow-x: hidden;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.comment-box::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.imgtop {
  position: relative;
  left: 0;
  top: 0;
  height: 160px;
  transform: scale(0.8);
  transform: translateY(1rem);
  transition: transform 0.5s ease;
}

.imgOverlay {
  height: 10rem;
  width: 80%;
  z-index: 2;
  position: absolute;
  opacity: 0.4;
  bottom: 20px;
  left: 20px;
  object-fit: contain;
  transform: scale(0.8);
  transform: translateY(1rem);
  transition: transform 0.5s ease;
}

.imgOriginalDiv {
  height: 10rem;
  width: 80%;
  z-index: 1;
  position: relative;
  top: 0px;
  left: 0px;
  object-fit: contain;
}

.imgItemDetailsDiv {
  height: 25rem;
  width: 85%;
  z-index: 1;
  position: relative;
  top: 0px;
  left: 0px;
  object-fit: contain;
}
.card-img-top {
  object-fit: contain;
  height: 10rem;
  width: 80%;
  transform: scale(0.8);
  transform: translateY(1rem);
  transition: transform 0.5s ease;
}

.loading-img {
  object-fit:fill;
}

.imgtop:hover {
  transform: scale(1.2);
  transform: translateY(-1rem);
}

.card-img-top:hover {
  transform: scale(1.2);
  transform: translateY(-1rem);
}

.expired-popup {
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  text-align: center;
  position: fixed;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.71);
}

.expired-popup-inner {
  margin-top: 35vh;
  height: 200px;
  width: 400px;
  border-radius: 25px;
  box-shadow: 0 0 15px rgb(0 0 128 / 14%);
  background-color: #fff;
  padding: 20px;
}

.expired-popup-btn {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-top: 80px;
  padding: 5px;
}
.img-expired-sign {
  position: relative;
  display: flex;
  justify-content: center;
  height: 100px;
  width: 400px;
}
.flex-1{
  flex: 1;
  padding-left: 2rem;
  padding-right: 2rem;
}
.ad-container {
  contain: layout paint style;
  position: relative;
  aspect-ratio: 1/0.3; /* Example for 1000x300 ad ratio */
  overflow: hidden;
}